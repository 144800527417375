import { render, staticRenderFns } from "./Inicio.view.vue?vue&type=template&id=86dd2412&scoped=true&"
import script from "./Inicio.view.vue?vue&type=script&lang=js&"
export * from "./Inicio.view.vue?vue&type=script&lang=js&"
import style0 from "./Inicio.view.vue?vue&type=style&index=0&id=86dd2412&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86dd2412",
  null
  
)

export default component.exports