<template>
  <v-card v-if="model.id" class="elevation-2" tile>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DataTableMain from '@/components/tables/DataTableMain'
export default {
  name: 'ComprasDetails',
  components: {
    DataTableMain
  },
  props: ['model', 'onSuccess'],
  data: () => ({
    table: {
      header: {
        options: [],
        titles: [
          { text: 'Codigo Barras', value: 'articulo.caracteristicas.codigo', class: 'black--text' },
          { text: 'Producto', value: 'producto.caracteristicas.descripcion', class: 'black--text' },
          { text: 'Articulo', value: 'articulo.caracteristicas.descripcion', class: 'black--text' },

          { text: 'Cantidad', value: 'cantidad', class: 'black--text', align: 'center' },
          { text: 'Cantidad Recepcionada', value: 'cantidad_recepcion', class: 'black--text', align: 'center' },
          { text: 'Unidad', value: 'udm.nombre', class: 'black--text' },

          { text: 'Precio', value: 'DinamicImporte', fieldMain: 'precio_unitario', class: 'black--text' },
          { text: 'Importe', value: 'DinamicImporte', fieldMain: 'subtotal', class: 'black--text' },
          { text: 'Impuesto', value: 'DinamicImporte', fieldMain: 'total_impuesto', class: 'black--text' },
          { text: 'Total', value: 'DinamicImporte',  fieldMain: 'total', class: 'black--text' }
        ],
        loading: false,
        showSearch: true
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    }
  }),
  methods: {
    successAction (item) {
      item.item = this.model
      this.onSuccess(item)
    },
    tableAction (item) {
      this.onSuccess(item)
    }
  },
  watch: {
    model (row) {
      if (row.detalles) {
        // console.log('detalles', row.detalles)
        this.table.body.data = row.detalles
      }
    }
  }
}
</script>

<style>  
</style>
  