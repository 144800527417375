<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <ViewDetailsMain class="my-2" :object="objectDetail" type="CajasVentasDetails" :items-action="tableAction" />
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <ViewDetailsMain class="my-2" :object="objectDetail" type="HistoryMovementsDetails" :items-action="tableAction" />
      </v-col>
    </v-row>
    <MainFormDialog
      v-if="get_dialogMain.dialog"
      :model="object"
      :params="paramsDialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DataTableMain from '@/components/tables/DataTableMain';
import MainFormDialog from '@/components/dialogs/MainFormDialog';
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  name: 'VentasCajaView',
  components: {
    DataTableMain,
    MainFormDialog,
    ViewDetailsMain
  },
  data: () => ({
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'caja.options.toCreate' },
          {
            title: 'Reportes',
            icon: 'mdi-cloud-download',
            color: 'light-blue lighten-1',
            code: 'cajas.reporte',
            options: [
              { title: 'Reporte de Cajas en General', icon: 'mdi-clipboard-text', color: 'yellow darken-3', action: 'ExportCajasGral', code: 'venta.caja.general-excel' },
            ]
          }
        ],
        titles: [
          { text: 'Folio', value: 'folio', class: 'black--text' },
          { text: 'Nombre Caja', value: 'nombre', class: 'black--text' },
          { text: 'Sucursal', value: 'caracteristicas.sucursal.nombre', class: 'black--text' },
          { text: 'Asignado', value: 'CajaAsignado', class: 'black--text' },
          { text: 'Estatus', value: 'Status', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'Actions', class: 'black--text' },
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [],
      },
      footer: {
        paging: true,
      },
    },
    paramsDialog: {},
    object: {}
  }),
  mounted() {
    this.ExecuteUrl('GET/venta/cajas');
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'GETListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    // *==========*==========*==========*==========* \\
    successAction(item) {
      this[item.action](item);
    },
    refreshData(item) {
      this.table.header.loading = true;
      this.ExecuteUrl('GET/venta/cajas');
    },
    newData(item) {
      this.object = {};
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/venta/cajas/options[/{status_id}]'],
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create', form: 'process', with: 850, title: 'Crear Caja',
      });
    },
    tableAction(item) {
      this[item.action](item);
    },
    toAssign(item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({ type: 'create', form: 'process', with: 550, title: 'Asignar Caja' });
    },
    toOpen(item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({ type: 'create', form: 'process', with: 550, title: 'Abrir Caja' })
    },

    toDeposit(item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create', form: 'process', with: 650, title: 'Deposito Caja',
      });
    },
    toWithdraw(item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create', form: 'process', with: 650, title: 'Retiro de Caja',
      });
    },
    toRepay(item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create', form: 'process', with: 850, title: 'Devolucion',
      });
    },

    toBalance(item) {
      this.object = item;
      const ingresos = item.item.last_movement.detalles.filter( (mov) => mov.descripcion.descripcion && mov.descripcion.status_id === 24 );
      const egresos = item.item.last_movement.detalles.filter( (mov) => mov.descripcion.descripcion && mov.descripcion.status_id === 25 );
      let totalEgress = 0
      egresos.map(item=>{ totalEgress += item.descripcion.monto });

      if (ingresos.length <= 0) {
        this.$swal({
          type: 'warning',
          icon: 'warning',
          title: 'Advertencia !',
          text: 'La caja no puede cerrar sin un ingreso agregado',
        })
        return
      }
      const montos = this.SumaImportesCorte(ingresos, egresos) // return { efectivo, tarjeta_debito, tarjeta_credito, retiros }
        const base = parseInt(item.item.last_movement.detalle.descripcion.monto)
        const obj = { importe: ((montos.efectivo + base) - totalEgress), importe_td: montos.tarjeta_debito,
          importe_tc: montos.tarjeta_credito, importe_retiros: totalEgress,
          tipo_cierre: 'COMPLETO', ingresos: ingresos, egresos: egresos,
          '10_centavos': 0, '20_nomeda': 0,'20_billete': 0,'50_nomeda': 0,'50_billete': 0,'1_nomeda': 0,'100_billete': 0,
          '2_nomeda': 0,'200_billete': 0,'5_nomeda': 0,'500_billete': 0,'10_nomeda': 0,'1000_billete': 0 }
        this.paramsDialog = {
          url: this.get_urls['POST/venta/cajas/execute'],
          message: 'Registro Agregado Correctamente',
          returnObject: true,
          setmodel: true,
          data: obj
        };
        this.SHOW_DIALOG_MAIN({ type: 'cierre-caja', form: 'process', with: '60%', title: `Cerrar Caja - ${item.item.CajaAsignado}` });
    },
    SumaImportesCorte(rows, rowse) {
      let retiros = 0
      let efectivo = 0
      let tarjeta_debito = 0
      let tarjeta_credito = 0
      rows.map(row => {
        if(row.descripcion.pago) {
          if (row.descripcion.pago.forma_pago.nombre === 'EFECTIVO') efectivo += row.descripcion.pago.importe
          if (row.descripcion.pago.forma_pago.nombre === 'TARJETA CREDITO') tarjeta_debito += row.descripcion.pago.importe
          if (row.descripcion.pago.forma_pago.nombre === 'TARJETA DEBITO') tarjeta_credito += row.descripcion.pago.importe
        } else {
          if (row.descripcion.forma_pago.nombre === 'EFECTIVO') efectivo += row.descripcion.monto
          if (row.descripcion.forma_pago.nombre === 'TARJETA CREDITO') tarjeta_debito += row.descripcion.monto
          if (row.descripcion.forma_pago.nombre === 'TARJETA DEBITO') tarjeta_credito += row.descripcion.monto
        }
      })
      rowse.map(rowe => {
        retiros += rowe.descripcion.monto
      })
      return { efectivo, tarjeta_debito, tarjeta_credito, retiros }
    },
    ExportCajasGral (item) {
      this.object = { nombre: 'Cajas_Generales'}
      this.paramsDialog = {
        form: this.get_SearchCajasForm,
        url: this.get_urls['POST/venta/cajas/reportes/cajas-generales'],
        message: 'Registros no Encontrados',
        exportData: true,
        setmodel: true,
        // returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'reporteCajas', with: 650, title: 'REPORTE DE CAJAS' })
    },
    // *==========*==========*==========*==========* \\
    ExecuteUrl(url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url];
        this.GETListObjectsService({ url: router, data: {} });
      } else {
        this.GETUrlServices();
      }
    },
  },
  computed: {
    ...mapGetters(['get_urls', 'get_dialogMain', 'get_objects', 'get_SearchCajasForm']),
    objectDetail () {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0];
      }
      return {};
    }
  },
  watch: {
    get_urls() {
      this.ExecuteUrl('GET/venta/cajas');
    },
    get_objects(rows) {
      this.table.modelMain = [];
        if (rows.length > 0) {
          this.table.modelMain.push(rows[0]);
        }
      this.table.header.loading = false;
      this.table.body.data = rows;
    },
  },
};
</script>

<style>

</style>
