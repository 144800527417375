<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
      </v-col>
      <v-col cols="12">
        <ViewDetailsMain class="my-2" type="AjusteInventarioDetails" :object="objectDetail" :items-action="tableAction" />
      </v-col>
    </v-row>
    <MainFormDialog
      v-if="get_dialogMain.dialog"
      :model="object"
      :params="paramsDialog"
      :on-success="ResponseForm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import { Permission } from '@/mixins/permissionMain'
export default {
  name: 'InvInventarioGralView',
  mixins: [Permission],
  components: {
    DataTableMain,
    ViewDetailsMain,
    MainFormDialog,
  },
  data: () => ({
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'inventario.ajuste.options.toInitInventoryAdjustment' }
        ],
        titles: [
          { text: 'Folio', value: 'folio', class: 'black--text' },
          { text: 'Sucursal', value: 'sucursal.nombre', class: 'black--text' },
          { text: 'Almacen', value: 'almacen.nombre', class: 'black--text' },
          { text: 'Empleado', value: 'usuario.empleado.NombreCompleto', class: 'black--text' },

          { text: 'Estatus', value: 'Status', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: 'Fecha', value: 'Created', class: 'black--text' },
          { text: '', value: 'Actions', class: 'black--text', align: 'right' },
        ],
        loading: false,
        showSearch: true
      },
      body: {
        data: [],
        actions: [],
      },
      footer: {
        paging: true,
      },
    },
    paramsDialog: {},
    object: {},
    params: {},
  }),
  mounted () {
    this.ExecuteUrl('GET/inventario/ajuste_inventario');
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'GETListObjectsService', 'ExportDataService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    successAction(item) {
      this[item.action](item);
    },
    tableAction(item) {
      this[item.action](item);
    },

    newData(item) {
      this.object = {};
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/inventario/ajuste_inventario/options[/{status_id}]'],
        url: this.get_urls['POST/inventario/ajuste_inventario/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create', form: 'process', with: 850, title: 'Ajuste de inventarios',
      });
    },
    refreshData(item) {
      this.table.header.loading = true;
      this.ExecuteUrl('GET/inventario/ajuste_inventario');
    },
    updateAjusteInventario ({ item, main }) {
      if(main.last_movement.estatus_id != 34) 
      {
        this.$swal({
          type: 'warning',
          icon: 'warning',
          title: 'Advertencia',
          text: 'el Inventarios ya fue aplicado, no es posible modificar'
        })
        return
      }

      this.object = {
        id: item.id,
        ajuste_inventario_id: item.ajuste_inventario_id,
        articulo_id: item.articulo_id,

        d_checkin: item.inventarios.diferente.checkin,
        d_stock: item.inventarios.diferente.stock,
        d_picking: item.inventarios.diferente.picking,
        d_invoiced: item.inventarios.diferente.invoiced,
        d_unbilled: item.inventarios.diferente.unbilled,
        
        a_checkin: item.inventarios.actual.checkin,
        a_stock: item.inventarios.actual.stock,
        a_picking: item.inventarios.actual.picking,
        a_invoiced: item.inventarios.actual.invoiced,
        a_unbilled: item.inventarios.actual.unbilled
      }
      this.paramsDialog = {
        url: this.get_urls['POST/inventario/ajuste_inventario/detalles'],
        form: this.get_UpdateAjusteInventarioForm,
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        returnObject: true
      };
      this.SHOW_DIALOG_MAIN({
        type: 'EditAjuste',
        with: '50%',
        title: `EDITAR AJUSTE - ${item.articulo.caracteristicas.descripcion}`,
      });
    },
    ExportdiferenteInventory ({ item }) {
      const router = this.get_urls['POST/inventario/ajuste_inventario/detalles/reportes']
      this.params.nombre = 'InventarioArticulosDiferentes';
      this.params.id = item.id;
      this.ExportDataService({ url: router, data: this.params })
    },
    // *==========*==========*==========*==========* \\
    toImportInventoryAdjustment (item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/inventario/ajuste_inventario/execute'],
        message: 'Registro Agregado Correctamente',
        formData: true
      };
      this.SHOW_DIALOG_MAIN({
        type: 'revision',
        form: 'process',
        with: 650,
        title: 'CARGAR EXCEL DE INVENTARIO',
      });
    },
    toValidateInventoryAdjustment (item) {
      // console.log(item)
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/inventario/ajuste_inventario/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'revision',
        form: 'process',
        with: '55%',
        title: 'VALIDAR AJUSTE DE INVENTARIO',
      });
    },
    toFinishInventoryAdjustment (item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/inventario/ajuste_inventario/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'revision',
        form: 'process',
        with: '55%',
        title: 'FINALIZAR AJUSTE DE INVENTARIO',
      });
    },
    // *==========*==========*==========*==========* \\
    // *==========*==========*==========*==========* \\
    ExecuteUrl(url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url]
        this.GETListObjectsService({ url: router })
      } else {
        this.GETUrlServices()
      }
    },
    ResponseForm ({ name, model }) {
      if (name === 'EditAjuste') {
        let diferencia = this.table.body.data.find(dif => dif.id === model.ajuste_inventario_id)
        let finded = -1;
        diferencia.diferencias.map((articulo, index) => {
          if (articulo.id === model.id) finded = index
        })
        if (finded !== -1) Object.assign(diferencia.diferencias[finded], model)
        else diferencia.diferencias.push(model)
      }
    }
  },
  computed: {
    ...mapGetters(['get_urls', 'get_dialogMain', 'get_objects',
    'get_UpdateAjusteInventarioForm']),
    objectDetail () {
        if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0]
      }
      return {}
    }
  },
  watch: {
    get_urls(val) {
      this.ExecuteUrl('GET/inventario/ajuste_inventario')
    },
    get_objects(data) {
      this.table.body.data = []
      this.table.modelMain = []
      if (data.length > 0) this.table.modelMain.push(data[0])
      this.table.body.data = data

      this.table.header.loading = false
    },
  }
}
</script>

<style>

</style>