<template>
  <div>
    <apexchart width="800" type="bar" :options="chartOptions" :series="series"/>
  </div>
</template>

<script>

export default {
  name: "BarChart",
  data() {
    return {
      chartOptions: {
        chart: { type: "bar", height: 350 },
        plotOptions: { bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" } },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        xaxis: { categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"] },
        yaxis: { title: { text: "$ (thousands)" } },
        fill: { opacity: 1 },
        tooltip: { y: { formatter: (val) => { return "$ " + val + " thousands" } } },
      },
      series: [
        {
          name: "Net Profit",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: "Net Profit",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        }
      ],
      
    };
  },
};
</script>
