<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card tile>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="4" md="3">
                <DatePicker :item-action="fieldDateInit" :on-success="actionFieldData" />
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <DatePicker :item-action="fieldDateFinal" :on-success="actionFieldData" />
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <v-btn color="success" @click="searchSales" dark>
                  <v-icon left dark>mdi-calendar-search</v-icon>
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <BarChart />
      </v-col>
      <v-col cols="12">
        <div class="text-md-h3 font-weight-bold text-center">Ordenes de Compras</div>
        <DataTableMain :datatable="tableCompras" :on-success="successAction" :items-action="successAction" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';
import BarChart from '@/components/charts/BarChart';
import DatePicker from '@/components/fields/DatePicker';
import DataTableMain from '@/components/tables/DataTableMain';
import MainFormDialog from '@/components/dialogs/MainFormDialog';
import { DatesUtils } from '@/mixins/datesUtilsMixin'
export default {
  mixins: [DatesUtils],
  components: {
    BarChart,
    DataTableMain,
    DatePicker,
  },
  data: () => ({
    tableCompras: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [],
        titles: [
          { text: 'Folio', value: 'folio', class: 'black--text' },
          { text: 'Fecha Solicitud', value: 'fecha', class: 'black--text' },
          { text: 'Proveedor', value: 'log.proveedor.nombre', class: 'black--text' },
          { text: 'Tipo Solicitud', value: 'tipo_solicitud.nombre', class: 'black--text' },

          { text: 'SubTotal', value: 'subtotal', class: 'black--text' },
          { text: 'Impuestos', value: 'total_impuesto', class: 'black--text' },
          { text: 'Total', value: 'total', class: 'black--text' },
          
          { text: 'Estatus', value: 'Status', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [],
      },
      footer: {
        paging: true,
      },
    },
    showFieldDate: true,
    fieldDateInit: {
      field: 'textDatePicker',
      name: 'Fecha Final',
      nameid: 'fecha_init',
      cols: 3,
    },
    fieldDateFinal: {
      field: 'textDatePicker',
      name: 'Fecha Final',
      nameid: 'fecha_final',
      cols: 3,
    },
    paramsDialog: {},
    object: {},
    params: {},
    caja: {},
  }),
  
  mounted() {
    // this.ExecuteUrlValidacion('GET/venta/cajas/abierto');
    const date = new Date()
    const fecha = this.formatoFecha(date, 'yyyy-mm-dd')
    this.params.fecha_init = fecha
    this.params.fecha_final = fecha
    this.ExecuteUrl('POST/empresa/dashboard');
  },
  
  methods: {
    ...mapActions(['GETUrlServices', 'POSTObjectsService']),
    ...mapMutations(['CLEAR_LIST_DATA']),
    searchSales () {
      this.ExecuteUrl('POST/empresa/dashboard');
    },
    actionFieldData(obj) {
      // console.log("CHANGE DATE-> ", obj);
      this.params[obj.id] = obj.data === undefined ? null : obj.data;
    },
    successAction (item) {
      this[item.action](item)
    },

    refreshData(item) {
      this.table.header.loading = true;
      this.ExecuteUrl('POST/venta');
    },

    ExecuteUrl(url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url];
        this.POSTObjectsService({ url: router, data: this.params });
      } else {
        this.GETUrlServices();
      }
    },
  }, 

  computed: {
    ...mapGetters(['get_urls', 'get_object']),
    objectDetail() {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0];
      }
      return {};
    },
  },

  watch: {
    get_object (row) {
      // console.log("Ventas-->> ", row);
      this.tableCompras.body.data = row.compas
      // if (rows) {
      //   this.table.modelMain = [];
      //   if (rows.length > 0) {
      //     this.table.modelMain.push(rows[0]);
      //   }
      //   this.table.body.data = rows;
      //   this.table.header.loading = false;
      // }
    },
  },
  
};
</script>

<style>

</style>
