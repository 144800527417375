<template>
  <v-container fluid>
    <v-card outlined :loading="CardLoading">
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-file-input show-size small-chips label="Cargar Arhivo" @change="setFile" outlined dense />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="sendImport.link"
              label="Link"
              outlined
              dense>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="sendImport.key"
              label="Clave"
              outlined
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-end>
          <v-btn class="green darken-1 mr-3" dark outlined @click="sendData">
            <v-icon left> mdi-cloud-upload </v-icon>Guardar
          </v-btn>
        </v-layout>
      </v-card-actions>
      <template slot="progress">
        <v-progress-linear color="light-green darken-3" height="5" indeterminate></v-progress-linear>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'dialogFaltas',
  props: {
    onSuccess: Function
  },
  data: () => ({
    tableHeader: [],
    tableData: [],
    sendImport: {},
    CardLoading: false
  }),
  methods: {
    ...mapActions(['POSTImportDataService']),
    ...mapMutations([]),
    beforeUpload (file) {
      const isLt1M = file.size / 1024 / 1024 < 2
      if (isLt1M) {
        return true
      }
      console.log({
        message: isLt1M + ' --Please do not upload files larger than 1m in size.',
        type: 'warning'
      })
      return false
    },
    handleSuccess ({ results, header }) {
      this.tableData = results
      this.tableHeader = header
    },

    setFile (file) {
      this.sendImport.file = file
    },
    sendData () {
      if (!this.sendImport.link){
        this.$swal({
          type: 'warning',
          icon: 'warning',
          title: 'Advertencia !',
          text: 'Campo Link no debe ser vacio',
          timer: 1500,
        })
        return
      }
      if (!this.sendImport.key){
        this.$swal({
          type: 'warning',
          icon: 'warning',
          title: 'Advertencia !',
          text: 'Campo Clave no debe ser vacio',
          timer: 1500,
        })
        return
      }
      this.CardLoading = true
      const formData = new FormData()
      formData.append('file', this.sendImport.file)
      formData.append('key', this.sendImport.key)
      this.POSTImportDataService({ url: this.sendImport.link, data: formData})
    }
  },
  components: {
  },
  computed: {
    ...mapGetters(['get_importData'])
  },
  watch : {
    get_importData (data) {
      this.CardLoading = false
      if (data.message) {
        this.$swal({
          type: 'error',
          icon: 'error',
          title: 'Oops...!',
          text: data.message
        })
        return
      }
      this.$swal({
        type: 'success',
        icon: 'success',
        title: 'Existoso !!',
        text: 'La importacion termino exitosamente.!! ',
        timer: 2000
      })
    }
  }
}
</script>

<style>
</style>
