<template>
  <v-app id="inspire">
    <template v-if="!$route.meta.allowAnonymous">
      <v-main>
        <router-view />
      </v-main>
    </template>
    <template v-else>
      <NavigationDrawer :toggle="drawer" />
      <ToolbarDrawer
        :name="$route.meta.name"
        @toggleNavigationBar="drawer = !drawer"
      />
      <v-main>
        <router-view />
      </v-main>
      <v-footer color="grey darken-1" app>
        <span class="white--text">&copy; {{ year() }}</span>
      </v-footer>
    </template>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data: () => ({
    drawer: false
  }),
  methods: {
    year () {
      const year = new Date().toISOString().substring(0, 4)
      return year
    }
  }
}
</script>
<style scoped>
#inspire {
  background-color: #EEEEEE;
}
</style>
