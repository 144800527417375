<template>
  <v-app
    id="login"
    class="blue darken-2"
  >
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm10 md6 lg5>
          <v-card class="elevation-4 pa-2" tile>
            <v-card-text>
              <div class="layout column align-center pa-1 ma-3">
                <img v-if="logo" :src="logo" contain alt="Vue Material Admin" width="90%" >
              </div>
              <v-form ref="form">
                <v-text-field
                  v-model="login.usuario"
                  label="Usuario"
                  :rules="fieldRequired"
                  required
                  outlined
                  dense
                />
                <v-text-field
                  v-model="login.password"
                  label="Contraseña"
                  :append-icon="showeye ? 'visibility' : 'visibility_off'"
                  :append-icon-cb="() => (value = !value)"
                  :rules="fieldRequired"
                  :type="showeye ? 'text' : 'password'"
                  required
                  outlined
                  dense
                  @click:append="showeye = !showeye"
                  @keyup.enter="loginAccess"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="!valid"
                color="success"
                block
                @click="loginAccess"
              >
                Login
              </v-btn>
            </v-card-actions>
            <v-progress-linear
              :active="loading"
              :indeterminate="true"
              color="green darken-3"
              height="8"
              absolute
              bottom
            />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Validate } from '@/mixins/validateFormMixin';
import router from '@/router';

export default {
  name: 'LoginView',
  components: {},
  mixins: [Validate],
  data: () => ({
    logo: null,
    login: {},
    valid: true,
    showeye: false,
    loading: false,
  }),
  beforeMount () {
    this.GETUrlLogoSistema();
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTLoginService', 'GETUrlLogoSistema']),
    ...mapMutations([]),
    loginAccess() {
      if (this.$refs.form.validate()) {
        this.sendLogin('POST/login');
        this.loading = true;
      }
    },
    sendLogin(url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url];
        this.POSTLoginService({ url: router, data: this.login });
      } else {
        this.GETUrlServices();
      }
    },
  },
  computed: {
    ...mapGetters(['get_urls', 'get_login', 'get_logo']),
  },
  watch: {
    get_urls(val) {
      this.sendLogin('POST/login');
    },
    get_login(val) {
      if (val) {
        this.loading = false;
        if (val.message) {
          this.$swal({
            type: 'error',
            icon: 'error',
            title: 'Error!',
            text: val.message,
          });
          return;
        }
        let sucursal_id = 0
        let almacen_id = 0
        let sucursal = ''
        const sucursalBase = val.user.sucursales.find(sucursal => sucursal.id === val.user.empleado.sucursal_id)
        if (sucursalBase) {
          sucursal = sucursalBase.nombre
          sucursal_id = sucursalBase.id
          almacen_id = JSON.parse(sucursalBase.pivot.configuracion).default
        }
        const user = {
          id: val.user.id,
          usuario: val.user.usuario,
          email: val.user.email,
          cve_empleado: val.user.empleado.cve_empleado,
          empleado: val.user.empleado.NombreCompleto,
          sucursal: sucursal,
          sucursal_id: sucursal_id,
          almacen_id: almacen_id,
          empresa_id: val.user.empleado.sucursal.empresa.id,
          empresa: val.user.empleado.sucursal.empresa.nombre,
        };

        this.$cookies.set('token_pv', val.access_token);
        this.$cookies.set('user_pv', user);
        localStorage.setItem('permisos', JSON.stringify(val.user.roles[0]));
        router.push({ name: 'inicio' });
      }
    },
    get_logo (logo) {
      this.logo = `${logo.formato}${logo.imagen}`
    }
  },
};
</script>
